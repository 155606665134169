import { useState, useEffect } from "react";
import { firebaseAuth } from "../firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";
import useAuthContext from "./useAuthContext.js";
import useTokens from "./useTokens";
import useLogout from "./useLogout";

const useLogin = () => {
  // Context for the loginUser()
  const { loginUser } = useAuthContext();

  // State Variables within the hook
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false); // Check if user has cancelled by clicking other buttons

  // Hooks
  // -----
  const { createSessionCookie } = useTokens();

  // useEffect() - Defence listener to detect if the user has clicked elsewhere and unmounted
  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  // setAutomaticLogoff() - set a 1hr login expiry time in localStorage
  // --------------------
  const setAutomaticLogoff = () => {
    const timeNow = new Date().getTime();
    const loginExpiryTime = timeNow + 1 * (60 * 60 * 1000); // 1hr
    localStorage.setItem("loginExpiryTime", loginExpiryTime); // computer will remember the cookie policy
  };

  // Login function
  // --------------
  const login = async (email, password) => {
    setError(null);
    setIsPending(true);
    try {
      const res = await signInWithEmailAndPassword(
        firebaseAuth,
        email,
        password
      );
      createSessionCookie(); // create a session cookie for the user
      loginUser(res.user); // update context of user
      setAutomaticLogoff(); // set a 1hr login expiry time in localStorage
      // update state
      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      if (!isCancelled) {
        setError(err.message);
        setIsPending(false);
      }
    }
  };

  return {
    login,
    error,
    isPending,
    setError,
  };
};

export default useLogin;
