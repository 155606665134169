import React, { useState } from "react";
import GoogleMapReact from "google-map-react";

function Map({ center, zoom, title }) {
  const [map, setMap] = useState(null);

  // Code for Marker
  // ---------------
  const onMapLoad = (map) => {
    const marker = new window.google.maps.Marker({
      position: { lat: center.lat, lng: center.lng },
      map: map,
      title: title,
    });
    setMap(map);
  };

  return (
    <div className="map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_MY_MAP_API_KEY }}
        defaultCenter={center}
        defaultZoom={zoom}
        onGoogleApiLoaded={({ map, maps }) => onMapLoad(map)}
      ></GoogleMapReact>
    </div>
  );
}

Map.defaultProps = {
  center: {
    lat: 1.28192,
    lng: 103.8501,
  },
  zoom: 17,
  title: "Crescent Asset Management Asia, CapitaGreen",
};

export default Map;
