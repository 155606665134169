import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";
import { getFunctions } from "firebase/functions";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCmiBWttDKdTWabXbI5uJ4NWh8pxk9Y9lI",
  authDomain: "crescentproject-376703.firebaseapp.com",
  projectId: "crescentproject-376703",
  storageBucket: "crescentproject-376703.appspot.com",
  messagingSenderId: "827183915650",
  appId: "1:827183915650:web:a3758348bcfbad32360a9b",
  measurementId: "G-RMSX54ZKXG",
};

// Initialize Connection to Firebase backend
// -----------------------------------------
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
// ----------------------------------
const firebaseAuth = getAuth(app);

// Initialize Firestore DB
// -----------------------
const firebaseDB = getFirestore(app);

// Initialize Firebase Storage
// ---------------------------
const firebaseStorageService = getStorage(app); // Storage Service
const firebaseStorage = ref(firebaseStorageService); // Reference to the storage

// Initialize Firebase Functions
// -----------------------------
const firebaseFunc = getFunctions(app);

export { firebaseAuth, firebaseDB, firebaseStorage, firebaseFunc };
