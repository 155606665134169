import React from "react";
import "./PDFViewer.css";

const PDFViewer = ({ currentPDF }) => {
  console.log("i am in the PDF Viewer");
  return (
    <div className="pdf-viewer-container">
      <iframe
        title="PDF Viewer"
        className="pdf-iframe"
        src={currentPDF}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default PDFViewer;
