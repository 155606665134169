import React, { useState } from "react";
import { Link } from "react-router-dom";
import usePasswordReset from "../hooks/usePasswordReset";
import Spinner from "../components/Spinner";
import "./PasswordReset.css";

const PasswordReset = () => {
  // state
  // -----
  const [email, setEmail] = useState(""); // set user input for email

  // hook
  // ----
  const {
    runPasswordResetEmail,
    errorEmailReset,
    isLoadingResetEmail,
    isSuccessEmailReset,
    setErrorEmailReset,
  } = usePasswordReset();

  // CSS styling variable - For input boxes, conditioned on whether password errors(red) or successful update (green)
  // --------------------
  let inputStatus = isSuccessEmailReset
    ? "success-input"
    : errorEmailReset
    ? "failed-input"
    : "";

  // Form Submission
  // ---------------
  const handlePasswordReset = (e) => {
    e.preventDefault();
    runPasswordResetEmail(email); // Use the function from the hook
  };

  // handleFocus() - handle input focus with an input reset after the user clicks back into input to correct entry
  // -------------
  const handleFocus = () => {
    if (errorEmailReset) {
      setErrorEmailReset(null); // setError to null in hook
      setEmail(""); // clear inputs
      inputStatus = ""; // ensure input css status is defaulted
    }
  };

  // Constants- Email Notifications
  // --------
  const passwordResetNotificationMessage = (
    <>
      We've sent a password reset link to the email address you provided. Please
      check your inbox (and spam/junk folder) to continue the password reset
      process. If you don't receive the email within a few minutes, please try
      again or <Link to="/contact">contact our support team.</Link>
    </>
  );
  const failedPassedResetNotificationMessage = (
    <>
      Email Not Recognized. Your Password Reset has Failed. Retry or{" "}
      <Link to="/contact">Contact us</Link> Directly for Help.
    </>
  );

  return (
    <div className="passwd-reset-page">
      <h2>Password Reset</h2>
      <form onSubmit={handlePasswordReset}>
        <div className="input-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={inputStatus}
            onFocus={handleFocus}
            disabled={isLoadingResetEmail || isSuccessEmailReset}
            required
          />
        </div>
        <button
          type="submit"
          disabled={isLoadingResetEmail || isSuccessEmailReset}
        >
          Reset My Password{" "}
        </button>
      </form>
      {isLoadingResetEmail && <Spinner />}
      {!isLoadingResetEmail && errorEmailReset && (
        <p className="failed-card">{failedPassedResetNotificationMessage}</p>
      )}
      {isSuccessEmailReset && (
        <p className="success-card">{passwordResetNotificationMessage}</p>
      )}
    </div>
  );
};

export default PasswordReset;
