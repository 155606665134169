import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "./ContactDetails.css";

function ContactDetails() {
  return (
    <div className="details-container">
      <div className="address">
        <h1 className="header">Contact Us</h1>
        <h3>Crescent Asset Management Asia</h3>
        <h3>138 Market Street,</h3>
        <h3>#34-01A CapitaGreen,</h3>
        <h3>Singapore 048946</h3>
      </div>
      <div className="communications">
        <div className="telephone">
          <span className="icon">
            <FontAwesomeIcon icon={faPhone} />
          </span>
          <span className="contact-label">Tel:</span>+65-6713-9962
        </div>
        <div className="email">
          <span className="icon">
            <FontAwesomeIcon icon={faEnvelope} />
          </span>
          <span className="contact-label">email:</span> info@crescentam.com
        </div>
      </div>
    </div>
  );
}

export default ContactDetails;
