const InvestorItems = [
  {
    id: 0,
    title: "Fund Performance",
    function: "displayPerformance",
    filePath: "",
    collectionName: "performance",
    storageName: "Performance",
  },
  {
    id: 1,
    title: "Pitch Book",
    function: "displayPitchBook",
    filePath: "",
    collectionName: "pitchBook",
    storageName: "PitchBook",
  },
  {
    id: 2,
    title: "Investor Letters",
    function: "displayInvLetterSidebar",
    filePath: "",
    years: [2017, 2018, 2019, 2020, 2021, 2022, 2023],
    collectionName: "investmentLetters",
    storageName: "InvestmentLetters",
  },
  {
    id: 3,
    title: "SS&C Portal",
    function: "displaySSCPortal",
    filePath: "https://secure2.globeop.com/gobookV2",
    collectionName: "",
    storageName: "",
  },
  {
    id: 4,
    title: "Change Password",
    function: "changePasswd",
    filePath: "/passwdreset",
    collectionName: "",
    storageName: "",
  },
];

export default InvestorItems;
