import { useEffect, useState, useRef } from "react";
import { firebaseDB, firebaseStorage } from "../firebase/config";
import { query, collection, getDocs } from "firebase/firestore"; // Firebase DB
import { ref, getDownloadURL } from "firebase/storage"; // Firebase Storage

const useStorage = (items) => {
  // State
  // -----
  const [urlsList, setUrlsList] = useState([]);
  const prevUrlsListRef = useRef();

  // useEffect()
  // -----------
  useEffect(() => {
    const fetchFilenamesAndDownloadUrls = async () => {
      // Check if the URL links have already been cached
      if (prevUrlsListRef.ecurrent && prevUrlsListRef.current.length > 0) {
        // if previous urls exist, use them and skip fetching
        setUrlsList(prevUrlsListRef.current);
        return;
      }
      try {
        // Map over each item to create promises for fetching URLs
        const allUrlsPromises = items.map(async (item) => {
          const { filePath, collectionName, storageName } = item;
          // Query FirebaseDB for Actual FileNames
          const q = query(collection(firebaseDB, collectionName));
          const querySnapshot = await getDocs(q);
          const queryDocs = querySnapshot.docs.map((doc) => doc.data());
          const fileNames = queryDocs.map((obj) => Object.values(obj)[0]);
          // console.log("fileNames:::", fileNames);
          // Create an array of promises to fetch download URLs associated with Filenames
          const urlPromises = fileNames.map((filename) => {
            const storageRef = ref(
              firebaseStorage,
              `${storageName}/${filePath}${filename}`
            );
            return getDownloadURL(storageRef);
          });
          // console.log("urlPromises:::", urlPromises);
          // Return resolved URLs for the item
          return await Promise.all(urlPromises); // Return all url links within a collection
        });
        const allUrls = await Promise.all(allUrlsPromises); // Return all url links across all collections
        prevUrlsListRef.current = allUrls; // Cache the fetched URLs in ref
        setUrlsList(allUrls); // Set the state with the fetched URLs
      } catch (error) {
        console.error("Error fetching filenames or download URLs:", error);
      }
    };

    fetchFilenamesAndDownloadUrls();
  }, [items]);
  return { urlsList };
};

export default useStorage;
