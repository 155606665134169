import React, { useMemo, useState, useEffect } from "react";
import useStorageInvLetters from "../hooks/useStorageInvLetters";
import InvestorItems from "../data/InvestorItems";
import "./InvestorLetterCards.css";

const InvestorLetterCards = ({ year, increaseTokenCount }) => {
  // Constants
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // State
  // -----
  const [currentYear, setCurrentYear] = useState(year);

  // useEffect() - used to detect when there is a year change
  // -----------
  useEffect(() => {
    console.log("I AM IN USE-EFFECT");
    setCurrentYear(year);
  }, [year]);

  // itemsForUrls() - binds sidebar items with url links
  // --------------
  // N.b., useMemo prevents a rebuild of this function unless the items list changed. Otherwise it creates an infinite loop
  // within the useEffect of useStorage as its dependency is itemsForUrls.

  const itemsForUrls = useMemo(() => {
    return InvestorItems.filter(
      (item) => item.title === "Investor Letters"
    ).map((item) => ({
      filePath: `${year}/`,
      collectionName: item.collectionName,
      storageName: item.storageName,
    }));
  }, [year]);
  // console.log("YEAR", year);
  console.log("ITEMS", itemsForUrls);
  const { urlsList } = useStorageInvLetters(itemsForUrls, year);
  const urlsListFlatttened = urlsList[0]; // Flatten out the array of arrays
  // console.log("The URL LIST", urlsListFlatttened);

  // Map Reducer - to convert months and urls arrays into an obj (accumulator), key=months, value=url links
  // -----------
  const monthsToLinksObject = months.reduce((obj, month, index) => {
    if (urlsListFlatttened && urlsListFlatttened[index]) {
      obj[month] = urlsListFlatttened[index];
    }
    return obj;
  }, {}); // {} is the initial value for the reducer

  // selectLetter() - Opens selected letter in side window
  // --------------
  const selectLetter = (url) => {
    increaseTokenCount();
    window.open(url, "_blank");
  };

  return (
    <div className="yearly-cards-container">
      <h1
        className={`cards-title ${
          currentYear === year ? " title-fade-in-animation" : ""
        }`}
      >
        {year}
      </h1>
      <div className="cards-grid">
        {Object.entries(monthsToLinksObject).map(([month, url]) => (
          <div
            key={month}
            className="month-card"
            onClick={() => selectLetter(url)}
          >
            {month}
          </div>
        ))}
      </div>
    </div>
  );
};

export default InvestorLetterCards;
