import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useStorage from "../hooks/useStorage";
import InvestorItems from "../data/InvestorItems";
import "./InvestorSidebar.css";

const InvestorSidebar = ({
  changePDF,
  isDisplayInvLetterSidebar,
  selectTheInvLetterYear,
  showInvLetterCards,
  increaseTokenCount,
}) => {
  // State
  // -----
  // const [isDisplayInvLetterSidebar, setIsDisplayInvLetterSidebar] =
  //   useState(false);

  // Constants - pointers to investor item objects
  // ---------
  const pitchBookObj = InvestorItems.find(
    (item) => item.title === "Pitch Book"
  );

  const performanceObj = InvestorItems.find(
    (item) => item.title === "Fund Performance"
  );

  const portalObj = InvestorItems.find((item) => item.title === "SS&C Portal");

  const changePasswdObj = InvestorItems.find(
    (item) => item.title === "Change Password"
  );

  // Hooks
  // -----
  const navigate = useNavigate();

  // itemsForUrls() - binds sidebar items with url links
  //---------------
  // N.b., useMemo prevents a rebuild of this function unless the items list changed. Otherwise it creates an infinite loop
  // within the useEffect of useStorage as its dependency is itemsForUrls.
  const itemsForUrls = useMemo(() => {
    return InvestorItems.filter(
      (item) =>
        item.title !== "Investor Letters" &&
        item.title !== "SS&C Portal" &&
        item.title !== "Change Password"
    ).map((item) => ({
      filePath: item.filePath,
      collectionName: item.collectionName,
      storageName: item.storageName,
    }));
  }, []);
  const { urlsList } = useStorage(itemsForUrls);
  // console.log("urlssss", urlsList);

  //displayPerformance -launches Return Performance pdf in page
  // -----------------
  const displayPerformance = () => {
    increaseTokenCount();
    const id = performanceObj.id;
    changePDF(urlsList[id]);
  };

  // displayPitchBook - launches inv pitchbook in page
  // ----------------
  const displayPitchBook = () => {
    increaseTokenCount();
    const id = pitchBookObj.id;
    changePDF(urlsList[id]);
  };

  // displayInvLetterSidebar() - opens up sidebar to show years for inv letters
  // -------------------------
  const displayInvLetterSidebar = () => {
    increaseTokenCount();
    // setIsDisplayInvLetterSidebar(!isDisplayInvLetterSidebar);
    selectTheInvLetterYear(2023);
    showInvLetterCards();
  };

  // displaySSCPortal() - opens up tab with SS&C portal
  // ------------------
  const displaySSCPortal = () => {
    window.open(portalObj.filePath, "_blank");
  };

  // changePasswd() - redirects to Change Password Page
  // --------------
  const changePasswd = () => {
    increaseTokenCount();
    console.log("I AM IN CHANGE PASSWD");
    navigate(changePasswdObj.filePath);
  };

  // Function Map - maps actual function references to sidebar onClicks as each item has a different function.
  // ------------
  const functionMap = {
    displayInvLetterSidebar,
    displayPitchBook,
    displayPerformance,
    displaySSCPortal,
    changePasswd,
  };

  return (
    <div className="sidebars-container">
      <div className="investorSidebar">
        <ul>
          {InvestorItems.map((item, index) => (
            <li key={index} onClick={functionMap[item.function]}>
              {item.title}
            </li>
          ))}
        </ul>
      </div>
      <div
        className={`popoutSidebar ${
          !isDisplayInvLetterSidebar ? "popoutSidebar-closed" : ""
        }`}
      >
        <ul>
          {InvestorItems.map((item, index) =>
            item.years
              ? item.years.map((year, yearIndex) => (
                  <li
                    key={yearIndex}
                    onClick={() => selectTheInvLetterYear(year)}
                  >
                    {year}
                  </li>
                ))
              : null
          )}
        </ul>
      </div>
    </div>
  );
};

export default InvestorSidebar;
