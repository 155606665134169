import { useState } from "react";
import { firebaseAuth, firebaseFunc } from "../firebase/config";
import { httpsCallable } from "firebase/functions";
import axios from "axios";
import Cookies from "universal-cookie";

const useTokens = () => {
  // constants
  // ---------
  const URLverifyIDToken =
    "https://us-central1-crescentproject-376703.cloudfunctions.net/verifyToken";

  const URLcreateSessionCookie =
    "https://us-central1-crescentproject-376703.cloudfunctions.net/createSessionCookie";

  const URLverifySessionCookie =
    "https://us-central1-crescentproject-376703.cloudfunctions.net/verifySessionCookie";

  const cookies = new Cookies();

  // State
  // -----
  const [isCookieCreationPending, setCookieCreationPending] = useState(false);
  const [isVerificationPending, setIsVerificationPending] = useState(false);

  // tokenExpiration() - helper fn to help show the expiry on a user ID token
  // -----------------
  const tokenExpiration = (token) => {
    console.log("token inside tokenExpiration: ", token);
    const decodedToken = JSON.parse(atob(token.split(".")[1])); //atob decode JWT base 64 to String
    const expirationDate = new Date(decodedToken.exp * 1000);
    const day = expirationDate.toLocaleDateString();
    const time = expirationDate.toLocaleTimeString();

    console.log("Expires at:", day, time);
  };

  // getUserIDToken() - get the user ID token from the firebase authetication
  // ----------------
  const getUserIDToken = async () => {
    try {
      const idToken = await firebaseAuth.currentUser.getIdToken(false); // false so as to prevent the token refresh
      console.log("idToken in getUserIDToken()", idToken);
      // tokenExpiration();
      return idToken;
    } catch (err) {
      console.error("Error Getting Token from Firebase Authentication: ", err);
    }
    return false; // ensure that fn has a return on all outcomes
  };

  // verifyTokenInCloud() - use firebase administration to to test if the user ID token has expired
  // --------------------
  const verifyIDTokenInCloud = async (idToken) => {
    try {
      const response = await axios.post(
        URLverifyIDToken,
        { token: idToken },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }
      console.log("VerifyIDTokenInCloud() Success:", response.data);
      return response.data;
    } catch (error) {
      console.error(
        "VerifyIDTokenInCloud() - There was a problem with the fetch operation:",
        error
      );
      throw error;
    }
  };

  // isUserIDTokenValid() - finds user ID token, checks its validity, returns true or false
  // --------------
  const isUserIDTokenValid = async () => {
    const firebaseIDToken = await getUserIDToken();
    const tokenData = await verifyIDTokenInCloud(firebaseIDToken);
    console.log("The Token is: ", tokenData ? true : false);
    return tokenData ? true : false;
  };

  // createSessionCookie()
  // ---------------------
  const createSessionCookie = async () => {
    console.log("Starting Create Cookie....");
    setCookieCreationPending(true);
    try {
      const idToken = await getUserIDToken();
      console.log("createSessionCookie has the ID Token", idToken);

      const response = await axios.post(
        URLcreateSessionCookie,
        { token: idToken },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = response.data;
      console.log("Successfully createSessionCookie: ", data);

      if (data.sessionCookie && data.maxAge) {
        document.cookie = `__session=${data.sessionCookie}; max-age=${data.maxAge}`;
      } else {
        console.error(
          "CreateSessionCookie(): Error retrieving session cookie or max age."
        );
      }
      setCookieCreationPending(false);
    } catch (error) {
      console.error(
        "CreateSessionCookie(): Error creating session cookie:",
        error
      );
      setCookieCreationPending(false);
    }
  };

  // getSessionCookie() - Retrieve session cookie stored on browser
  // ------------------
  const getSessionCookie = () => {
    // console.log("The document.cookie: ", document.cookie);
    const sessionCookie = cookies.get("__session");
    // console.log("unpacking document.cookie: ", sessionCookie);
    if (sessionCookie) {
      console.log(
        "getSessionCookie(): Success.  Session Cookie Found on Client: ",
        sessionCookie
      );
      return sessionCookie;
    } else {
      console.log(
        "getSessionCookie(): Error Cannot find sessionCookie on Client. Retrying..."
      );
    }
  };

  // verifySessionCookie()
  // ---------------------
  const verifySessionCookie = async (sessionToken) => {
    setIsVerificationPending(true);
    // tokenExpiration(sessionToken); // gives expiration time of token
    try {
      const response = await axios.post(
        URLverifySessionCookie,
        { token: sessionToken },
        {
          headers: {
            "Content-Type": "application/json",
          },
          // withCredentials: true,
        }
      );

      const data = response.data;

      if (data.error) {
        console.log(
          "verifySessionCookie(): Not authenticated or session expired"
        );
        // await firebaseAuth.signOut(); handled by useLogout()
        setIsVerificationPending(false);
        return null;
      } else {
        // User is authenticated
        console.log(
          "verifySessionCookie(): Successful Cookie Verification. User's UID:",
          data.uid
        );
        setIsVerificationPending(false);
        return data;
      }
    } catch (error) {
      console.error(
        "verifySessionCookie(): Error verifying session cookie:",
        error
      );
      setIsVerificationPending(false);
    }
  };

  // endCookieSession() - Wipes the cookie session from both the server and the client
  // ------------------
  const endCookieSession = async () => {
    try {
      // Call the invalidateUserSession cloud function
      const invalidateSession = httpsCallable(
        firebaseFunc,
        "invalidateUserSession"
      );
      await invalidateSession();
      // remove session cookie from client
      cookies.remove("__session");
      return true;
    } catch (err) {
      console.error(
        "endCookieSession(): Error with invalidating session on server: ",
        err
      );
    }
    return false;
  };

  const isSessionCookieValid = async () => {
    console.log("Starting isSessionCookieValid()");
    const sessionCookie = getSessionCookie();
    const cookieData = await verifySessionCookie(sessionCookie);
    return cookieData ? true : false;
  };

  return {
    getUserIDToken,
    verifyIDTokenInCloud,
    isUserIDTokenValid,
    createSessionCookie,
    getSessionCookie,
    verifySessionCookie,
    isCookieCreationPending,
    isVerificationPending,
    isSessionCookieValid,
    endCookieSession,
  };
};

export default useTokens;
