import React from "react";
import LightBox from "../components/LightBox";
import "./TeamPage.css";

function TeamPage() {
  return (
    <>
      <div className="team-page">
        <div className="partner-cards-container">
          <h1 className="team-title"> The Crescent Management Team</h1>
          <LightBox />
        </div>
      </div>
    </>
  );
}

export default TeamPage;
