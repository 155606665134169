import { useState } from "react";
import { firebaseDB } from "../firebase/config";
import { addDoc, collection } from "firebase/firestore";

const useRequestLogin = () => {
  // constants
  // ---------
  const requestDB = "requestLogin";

  // State
  // -----
  const [isLoadingLoginRequest, setIsLoadingLoginRequest] = useState(false);
  const [isLoginRequestSuccess, setIsLoginRequestSuccess] = useState(false);
  const [errorLoginRequest, setErrorLoginRequest] = useState(null);

  // submitForm() - send requestLogin submission to DB
  // ------------
  const submitForm = async (form) => {
    setIsLoadingLoginRequest(true);
    setIsLoginRequestSuccess(false);
    setErrorLoginRequest(null);
    try {
      //   await firebaseDB.collection(requestDB).add(form);
      await addDoc(collection(firebaseDB, requestDB), form);
      setIsLoginRequestSuccess(true);
      console.log("succesful requestLogin submission to DB");
    } catch (err) {
      setErrorLoginRequest(err);
      console.log(err);
    }
    setIsLoadingLoginRequest(false);
  };

  return {
    submitForm,
    isLoadingLoginRequest,
    isLoginRequestSuccess,
    errorLoginRequest,
  };
};

export default useRequestLogin;
