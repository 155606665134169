import React, { useState, useEffect } from "react";
import useAuthContext from "../hooks/useAuthContext";
import useTokens from "../hooks/useTokens";
import useLogout from "../hooks/useLogout";
import Spinner from "../components/Spinner";
import PDFViewer from "../components/PDFViewer";
import InvestorSidebar from "../components/InvestorSidebar";
import InvestorLetterCards from "../components/InvestorLetterCards";

import "./InvestorPage.css";

const InvestorPage = () => {
  // Context
  // -------
  const { isLoading } = useAuthContext();

  // Variable
  // --------
  const today = new Date();
  const currentYear = today.getFullYear();

  // State
  // -----
  const [currentPDF, setCurrentPDF] = useState(null);
  const [isDisplayInvLetterSidebar, setIsDisplayInvLetterSidebar] =
    useState(false); // state for InvLetter Sidebar
  const [isDisplayInvLetterCards, setIsDisplayInvLetterCards] = useState(false); // state for InvLetter Cards
  const [invLetterYear, setInvLetterYear] = useState(currentYear);
  const [countTokenChecks, setCountTokenChecks] = useState(0); // trick used to trigger useEffect() on each click

  // Hooks
  // -----
  const {
    isCookieCreationPending,
    isVerificationPending,
    isSessionCookieValid,
  } = useTokens();
  const { logout, checkIfLoginExpired } = useLogout();

  // useEffect() - on launch, set up interval check if the login has expired
  // -----------
  useEffect(() => {
    checkIfLoginExpired(); // interval check if the login has expired
  }, []);

  // useEffect() - Listens for requests for data which triggers a check for Token validity
  // -----------
  useEffect(() => {
    const validateToken = async () => {
      try {
        console.log(
          "InvestorPage UseEffect is checking if Cookie Session is Valid"
        );
        isSessionCookieValid();
        const queryToken = await isSessionCookieValid();
        console.log("InvestorPage UseEffect has found Token: ", queryToken);
      } catch (err) {
        console.log(
          "InvestorPage UseEffect Failed to check if Cookie Session Valid: ",
          err.message
        );
        if (!isCookieCreationPending && isVerificationPending) {
          logout();
        }
      }
    };
    validateToken(); // inner fn
  }, [countTokenChecks]);

  // increaseTokenCount() - create a state change in order to trigger useEffect
  // --------------------
  const increaseTokenCount = () => {
    setCountTokenChecks((prev) => prev + 1);
    console.log(
      "increaseTokenCount(): token count has increased",
      countTokenChecks
    );
  };

  // ChangePDF() - function to be passed as a prop to <InvestorSideBar />
  // -----------
  const changePDF = (url) => {
    setIsDisplayInvLetterSidebar(false); // ensure that the investorLetter Sidebar is hidden
    setIsDisplayInvLetterCards(false); // ensure that the investor cards are hidden if launch a pdf
    setCurrentPDF(url);
  };

  // selectTheInvLetterYear(year) - function to be passed as a prop to <InvestorSideBar />
  // -----------------
  const selectTheInvLetterYear = (year) => {
    setInvLetterYear(year);
  };

  // showInvLetterCards() - function to be passed as a prop to <InvestorSideBar />
  // --------------------
  const showInvLetterCards = () => {
    setCurrentPDF(null); // close any open pdf that is already open
    setIsDisplayInvLetterSidebar(!isDisplayInvLetterSidebar);
    setIsDisplayInvLetterCards(!isDisplayInvLetterCards);
  };

  // isLoading - Checking User Credentials
  // ---------
  if (isLoading && isCookieCreationPending && isVerificationPending) {
    return <Spinner />;
  }

  return (
    <div className="investor-page">
      <InvestorSidebar
        changePDF={changePDF}
        isDisplayInvLetterSidebar={isDisplayInvLetterSidebar}
        selectTheInvLetterYear={selectTheInvLetterYear}
        showInvLetterCards={showInvLetterCards}
        increaseTokenCount={increaseTokenCount}
      />
      {currentPDF && <PDFViewer currentPDF={currentPDF} />}
      {isDisplayInvLetterCards && (
        <InvestorLetterCards
          year={invLetterYear}
          increaseTokenCount={increaseTokenCount}
        />
      )}
    </div>
  );
};

export default InvestorPage;
