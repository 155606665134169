import React from "react";
import "./NotFound404.css";

const NotFound404 = () => {
  return (
    <div className="not-found-page">
      <div className="not-found-container">
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for doesn't exist.</p>
      </div>
    </div>
  );
};

export default NotFound404;
