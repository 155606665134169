import React, { useState, useEffect } from "react";
import sailImg from "../assets/sail.webp";
import "./SpinnerHomePage3.css";

const Spinner = () => {
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  useEffect(() => {
    // Create an image element to preload the background image
    const backgroundImage = new Image();
    backgroundImage.src = sailImg;

    // Set the event handler for when the image is loaded
    backgroundImage.onload = () => {
      setBackgroundLoaded(true);
    };
  }, []);

  useEffect(() => {
    // Hide the spinner once the background image has loaded
    if (backgroundLoaded) {
      // You can add a timeout to ensure a smooth transition
      setTimeout(() => {
        document.querySelector(".spinner-overlay").style.display = "none";
      }, 2500); // Adjust the delay as needed
    }
  }, [backgroundLoaded]);

  return (
    <div className={`spinner-overlay ${backgroundLoaded ? "hidden" : ""}`}>
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    </div>
  );
};

export default Spinner;
