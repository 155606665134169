import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useChangePassword from "../hooks/useChangePassword.js";
import Spinner from "../components/Spinner.jsx";
import "./ChangePassword.css";

const ChangePassword = () => {
  // state
  // -----
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");

  // hooks
  // -----
  const {
    // checkCredentials,
    changePassword,
    isLoadingPassword,
    error,
    isSuccess,
    setError,
    passwordError,
    setPasswordError,
  } = useChangePassword();
  const navigate = useNavigate();

  // CSS styling variable - For input boxes, conditioned on whether password errors(red) or successful update (green)
  // --------------------
  let inputStatus = isSuccess
    ? "success-input"
    : passwordError || error
    ? "failed-input"
    : "";

  // clearInputs() - helper function to clear inputs on error
  // -------------
  const clearInputs = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmedPassword("");
  };

  // handleFocus() - handle input focus with a reset of errors after the user clicks back into input to correct entry
  // -------------
  const handleFocus = () => {
    if (error || passwordError) {
      setError(null); // setError to null in hook
      setPasswordError(null); // setPasswordError from incorrect input to null
      clearInputs(); // clear inputs
      inputStatus = ""; // ensure input css status is defaulted
    }
  };

  // handleSubmit() - Form submission to Firebase
  // --------------
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Old Password:", oldPassword, "New Password:", newPassword);
    changePassword(oldPassword, newPassword, confirmedPassword); // pass info to firebase auth via the useChangePassword hook
  };

  // handleDone() - On success, handle redirect to investor page
  // ------------
  const handleDone = () => {
    navigate("/investor");
  };

  return (
    <div className="change-password-page">
      <h2>Change Password</h2>
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <label>Old Password:</label>
          <input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            className={inputStatus}
            disabled={isLoadingPassword}
            onFocus={handleFocus}
            required
          />
        </div>
        <div className="input-group">
          <label>New Password:</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className={inputStatus}
            disabled={isLoadingPassword}
            onFocus={handleFocus}
            required
          />
        </div>
        <div className="input-group">
          <label>Confirm Password:</label>
          <input
            type="password"
            value={confirmedPassword}
            onChange={(e) => setConfirmedPassword(e.target.value)}
            className={inputStatus}
            disabled={isLoadingPassword}
            onFocus={handleFocus}
            required
          />
        </div>
        <button type="submit" disabled={isLoadingPassword}>
          Change Password
        </button>
      </form>
      {isLoadingPassword && <Spinner />}
      {!isLoadingPassword && error && <p className="failed-card">{error}</p>}
      {!isLoadingPassword && passwordError && !error && (
        <p className="failed-card">{passwordError}</p>
      )}
      {isSuccess && (
        <div className="success-card">
          <p>Password Updated Successfully!</p>
          <button onClick={handleDone}>Done</button>
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
