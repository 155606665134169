import { useState } from "react";
import { firebaseAuth } from "../firebase/config";
import {
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from "firebase/auth";

// useChangePassword Hook
// ======================
const useChangePassword = () => {
  // state
  const [isLoadingPassword, setIsLoadingPassword] = useState(false);
  const [error, setError] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [passwordError, setPasswordError] = useState(null);

  // checkCredentials
  // ----------------
  const checkCredentials = async (currentPassword) => {
    setIsLoadingPassword(true);
    setError(null);
    setIsSuccess(false);

    // get user credentials from firebase (email and passwd)
    const user = firebaseAuth.currentUser;
    if (user) {
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
      // check credentials
      try {
        await reauthenticateWithCredential(user, credential); // must confirm authenticati before changing passwd
        setIsSuccess(true);
        console.log("Credentials are Valid");
      } catch (err) {
        setError(err.message);
      }
    } else {
      setError("User not authenticated.");
    }
    setIsLoadingPassword(false);
  };

  // checkPasswordInputs()
  // ---------------------
  const checkPasswordInputs = (
    currentPassword,
    newPassword,
    confirmedPassword
  ) => {
    let passwdInputError;
    if (currentPassword === newPassword) {
      passwdInputError =
        "Error: Current and New Passwords are the same. Please change";
    } else if (newPassword !== confirmedPassword) {
      passwdInputError =
        "Error: New Password and Confirmed Passwords do not Match";
    } else {
      passwdInputError = null;
    }
    setPasswordError(passwdInputError); // cannot rely on useState to update in time as it is asynchronous

    return passwdInputError;
  };

  //---------------------------------------------------------------------------------------
  // changePassword()
  // ----------------
  const changePassword = async (
    currentPassword,
    newPassword,
    confirmedPassword
  ) => {
    setIsLoadingPassword(true);
    setError(null);
    setIsSuccess(false);

    const passwdInputError = checkPasswordInputs(
      currentPassword,
      newPassword,
      confirmedPassword
    );

    // if no password input error
    if (!passwdInputError) {
      // get user credentials from firebase (email and passwd)
      const user = firebaseAuth.currentUser;
      if (user) {
        const credential = EmailAuthProvider.credential(
          user.email,
          currentPassword
        );
        // Try changing password
        try {
          await reauthenticateWithCredential(user, credential); // must confirm authenticati before changing passwd
          await updatePassword(user, newPassword);
          setIsSuccess(true);
          console.log("Password Updated!");
        } catch (err) {
          setError("Current Password is incorrect");
        }
      } else {
        setError("User not authenticated. Password has not been changed");
      }
    }

    setIsLoadingPassword(false);
  };

  return {
    checkCredentials,
    changePassword,
    isLoadingPassword,
    error,
    isSuccess,
    setError,
    passwordError,
    setPasswordError,
  };
};

export default useChangePassword;
