import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import PartnerCard from "./PartnerCard";
import PartnerBios from "../data/PartnerBios.js";
import "./LightBox.css";

const Lightbox = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPartner, setCurrentPartner] = useState(0);

  const openLightbox = (partnerIndex) => {
    setIsOpen(true);
    if (partnerIndex > PartnerBios.length) {
      partnerIndex = 0;
    }
    setCurrentPartner(partnerIndex);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const incrementPartner = (partnerIndex) => {
    if (partnerIndex === PartnerBios.length - 1) {
      setCurrentPartner(0);
    } else {
      setCurrentPartner(partnerIndex + 1);
    }
  };

  const decrementPartner = (partnerIndex) => {
    console.log(partnerIndex);
    if (partnerIndex === 0) {
      console.log(PartnerBios.length - 1);
      setCurrentPartner(PartnerBios.length - 1);
    } else {
      setCurrentPartner(partnerIndex - 1);
    }
  };

  return (
    <div className="image-grid-container">
      <div className="image-grid">
        {PartnerBios.map((partner, index) => (
          <div
            className="card-grid"
            key={index}
            onClick={() => openLightbox(index)}
          >
            <h2>{partner.name}</h2>
            <p>{partner.position}</p>
            <img src={partner.photo} key={index} alt={partner.name} />
          </div>
        ))}
      </div>
      {isOpen && (
        <div className="lightbox">
          <PartnerCard
            name={PartnerBios[currentPartner].name}
            position={PartnerBios[currentPartner].position}
            photo={PartnerBios[currentPartner].photo}
            bio={PartnerBios[currentPartner].bio}
          />
          <div className="lightbox-buttons">
            <button
              className="button-icon"
              onClick={() => decrementPartner(currentPartner)}
            >
              <FontAwesomeIcon icon={solid("chevron-left")} />
            </button>
            <button
              className="button-icon"
              onClick={() => incrementPartner(currentPartner)}
            >
              <FontAwesomeIcon icon={solid("chevron-right")} />
            </button>
            <button className="button-icon" onClick={closeLightbox}>
              <FontAwesomeIcon icon={regular("circle-xmark")} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Lightbox;
