import React from "react";
import Map from "../components/Map";
import ContactDetails from "../components/ContactDetails";
import CapitaGreen from "../assets/CapitaGreen2.webp";
import CapitaGreenSign from "../assets/CapitaGreen4.webp";

import "./ContactPage.css";

function ContactPage() {
  return (
    <div className="contact-page">
      <figure className="capitagreen-sign-container">
        <img
          src={CapitaGreenSign}
          className="gallery_img"
          alt="Capita Green Sign"
        />
      </figure>
      <div className="contact-details-container">
        <ContactDetails />
      </div>
      <figure className="capitagreen-tower-container">
        <img
          src={CapitaGreen}
          className="gallery_img"
          alt="Capita Green Office Block"
        />
      </figure>
      <figure className="google-map-container">
        <Map />
      </figure>
    </div>
  );
}

export default ContactPage;
