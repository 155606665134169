import React from "react";
import { Link } from "react-router-dom";
import "./DisclaimerButtons.css";

const DisclaimerButtons = () => {
  return (
    <>
      <button className="disclaimer-button agree">
        <Link to="/login">I AGREE</Link>
      </button>
      <button className="disclaimer-button disagree">
        <Link to="/">I DISAGREE</Link>
      </button>
    </>
  );
};

export default DisclaimerButtons;
