import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useRequestLogin from "../hooks/useRequestLogin";
import Spinner from "../components/Spinner";
import "./RequestLogin.css";

const RequestLogin = () => {
  // Constants
  // ---------
  const successMessage =
    "Thank you for your registration. Your application is under review and we will get back to you shortly.";
  const errorMessage =
    "There has been an error. Please try submitting or contact us directly";

  // state
  // -----
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    jobTitle: "",
    email: "",
    reasonForRequest: "",
  });

  // hooks
  // -----
  const {
    submitForm,
    isLoadingLoginRequest,
    isLoginRequestSuccess,
    errorLoginRequest,
  } = useRequestLogin();
  const navigate = useNavigate();

  // CSS styling variable - For input boxes, conditioned on whether errors(red) or successful update (green)
  // --------------------
  let inputStatus = isLoginRequestSuccess
    ? "success-input"
    : errorLoginRequest
    ? "failed-input"
    : "";

  // Form Input to State
  // -------------------
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  // Form Submission
  // ---------------
  const handleSubmit = (e) => {
    e.preventDefault();
    submitForm(form);
  };

  // handleDone() - On success, handle redirect to investor page
  // ------------
  const handleDone = () => {
    navigate("/");
  };

  return (
    <div className="request-login-page">
      <h2>Request Login</h2>
      <form onSubmit={handleSubmit}>
        {Object.entries(form).map(([key, value]) => (
          <div key={key} className="input-group">
            <label>
              {key.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
                return str.toUpperCase();
              })}
              :
            </label>
            {key === "reasonForRequest" ? (
              <textarea
                name={key}
                value={value}
                className={inputStatus}
                onChange={handleChange}
                disabled={isLoadingLoginRequest || isLoginRequestSuccess}
              ></textarea>
            ) : (
              <input
                type="text"
                name={key}
                value={value}
                className={inputStatus}
                onChange={handleChange}
                required={key === "email" || key === "lastName"}
                disabled={isLoadingLoginRequest || isLoginRequestSuccess}
              />
            )}
          </div>
        ))}
        <button type="submit">Send Request</button>
      </form>
      {isLoadingLoginRequest && <Spinner />}
      {isLoginRequestSuccess && (
        <div className="success-card">
          <p>{successMessage}</p>
          <button onClick={handleDone}>Done</button>
        </div>
      )}
      {errorLoginRequest && <p className="failed-card">{errorMessage}</p>}
    </div>
  );
};

export default RequestLogin;
