import {
  LOGIN,
  LOGOUT,
  IS_AUTH_READY,
  IS_LOADING,
  SET_COOKIE_POLICY,
} from "./Types";
const AuthReducer = (state, action) => {
  switch (action.type) {
    case SET_COOKIE_POLICY:
      return { ...state, isAcceptCookiePolicy: action.payload };
    case LOGIN:
      return { ...state, user: action.payload };
    case LOGOUT:
      return { ...state, user: null };
    case IS_AUTH_READY:
      return { ...state, user: action.payload, isAuthReady: true };
    case IS_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

export default AuthReducer;
