import { useState } from "react";
import { firebaseAuth } from "../firebase/config";
import { sendPasswordResetEmail } from "firebase/auth";

function usePasswordReset() {
  // State
  // ------
  const [errorEmailReset, setErrorEmailReset] = useState(null);
  const [isLoadingResetEmail, setIsLoadingResetEmail] = useState(false);
  const [isSuccessEmailReset, setIsSuccessEmailReset] = useState(false);

  // runPasswordResetEmail()
  // -----------------------
  const runPasswordResetEmail = async (email) => {
    setIsLoadingResetEmail(true);
    try {
      await sendPasswordResetEmail(firebaseAuth, email);
      setIsSuccessEmailReset(true);
    } catch (err) {
      setErrorEmailReset(err.message);
    } finally {
      setIsLoadingResetEmail(false);
    }
  };

  return {
    runPasswordResetEmail,
    errorEmailReset,
    isLoadingResetEmail,
    isSuccessEmailReset,
    setErrorEmailReset,
  };
}

export default usePasswordReset;
