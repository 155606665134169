import React from "react";
export const disclaimer = (
  <div className="disclaimer-body">
    <h1 className="disclaimer-header">DISCLAIMER</h1>
    <p>
      By clicking the “I AGREE” button, you are deemed to be representing and
      warranting that: 1) you have read and understood the information contained
      on this page and accept the terms and conditions contained herein; 2) the
      applicable laws and regulations of your jurisdiction allow you to access
      the information on this webpage; and 3) you or any other person or entity
      you represent initiated the discussion, correspondence or other
      communications with Crescent Asset Management Asia or its representatives,
      which resulted in your requesting information regarding its funds, and
      none of Crescent Asset Management Asia or its representatives at any time
      directly or indirectly contacted you with respect to the provision of
      investment advisory services or investment in a Crescent Asset Management
      Asia fund prior to such unsolicited initiation of discussions,
      correspondence or other communications.
    </p>
    <br />
    <p>
      The information on this webpage is not intended for persons located or
      resident in jurisdictions where the distribution of such information is
      restricted or unauthorized. No action has been taken to authorize,
      register or qualify any of the Crescent Asset Management Asia funds or
      otherwise permit a public offering of any Crescent Asset Management Asia
      fund in any jurisdiction, or to permit the distribution of information in
      relation to any of the Crescent Asset Management Asia fund in any
      jurisdiction.
    </p>
    <br />
    <p>
      This webpage is not an advertisement and is not intended for public use or
      distribution. This website has been prepared for the purpose of providing
      general information only without taking account of any particular
      investor’s objectives, financial situation or needs and does not amount to
      an investment recommendation. An investor should, before making any
      investment decision, consider the appropriateness of the information in
      this website, and seek professional advice, having regard to the
      investor’s objectives, financial situation and needs. In all cases, anyone
      proposing to rely on or use the information contained on the website
      should independently verify and check the accuracy, completeness,
      reliability and suitability of the information. The information contained
      in this webpage does not constitute financial, investment, legal,
      accounting, tax or other professional advice or a solicitation for
      investment in Crescent Asset Management Asia’s funds, nor does it
      constitute an offer for sale of interests issued by funds that are managed
      or advised by Crescent Asset Management Asia. Any offer can only be made
      by the relevant offering documents, together with the relevant
      subscription agreement, all of which must be read and understood in their
      entirety, and only in jurisdictions where such an offer is in compliance
      with relevant laws and regulatory requirements.
    </p>
    <br />
    <p>
      To the best of its knowledge and belief, Crescent Asset Management Asia
      considers the information contained herein as accurate as at the date of
      publication. All information and opinions in this webpage are subject to
      change without notice. No representation or warranty is given, whether
      express or implied, on the accuracy, adequacy or completeness of
      information provided on the website or by third parties. The materials on
      this webpage could include technical inaccuracies or typographical errors,
      and could become inaccurate as a result of developments occurring after
      their respective dates. Crescent Asset Management Asia undertakes no
      obligation to maintain updates to such information. Any links to other
      websites contained within this webpage are for the convenience of the user
      only and do not constitute an endorsement by Crescent Asset Management
      Asia of these websites. Crescent Asset Management Asia is not responsible
      for the content of other websites referenced in this webpage.
    </p>
    <br />
    <p>
      Neither Crescent Asset Management Asia and their respective shareholders,
      directors, officers and employees assume any liabilities in respect of any
      errors or omissions on this webpage, or any and all responsibility for any
      direct or consequential loss or damage of any kind resulting directly or
      indirectly from the use of this webpage. Unless otherwise agreed with
      Crescent Asset Management Asia, any use, disclosure, reproduction,
      modification or distribution of the contents of this webpage, or any part
      thereof, is strictly prohibited. Crescent Asset Management Asia expressly
      disclaims any liability, whether in contract, tort, strict liability or
      otherwise, for any direct, indirect, incidental, consequential, punitive
      or special damages arising out of, or in any way connected with, your
      access to or use of this website.
    </p>
    <br />
    <p>
      Simulations, past and projected performance may not necessarily be
      indicative of future results. Figures may be taken from sources that are
      believed to be reliable but may not necessarily have been independently
      verified, and such figures should not be relied upon in making investment
      decisions. Crescent Asset Management Asia, its officers and employees do
      not assume any responsibility for the accuracy or completeness of such
      information. There is the risk of loss as well as the opportunity for gain
      when investing in funds managed or advised by Crescent Asset Management
      Asia.
    </p>
    <br />
    <p>
      Crescent Asset Management Asia Asset Management Singapore Pte. Ltd. holds
      a capital markets services licence for the provision of fund management
      services to eligible investors. Accordingly, this website and its contents
      is permitted only for the use of persons who are “institutional investors”
      or “accredited investors”, each within the meaning provided in the
      Singapore Securities and Futures Act (Cap.289). As an “institutional
      investor” and/or “accredited investor” certain disclosure requirements in
      relation to the contents of this website would not apply to you as a
      recipient. The products and services described in this website are
      available to such aforementioned categories of persons only. None of the
      contents of this website have been approved or endorsed by the MAS or any
      other global regulator.
    </p>
  </div>
);
