import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useLogin from "../hooks/useLogin";
import useAuthContext from "../hooks/useAuthContext";
import Spinner from "../components/Spinner";
import "./LoginPage.css";

function LoginPage() {
  // Context
  // -------
  const { user } = useAuthContext();

  // State
  // -----
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, error, isPending, setError } = useLogin();

  // Constants
  // ---------
  const LoginErrorMsg =
    "Sorry, we can't find an account with this email address and password. Please try again or reset your password.";

  // Hooks
  // -----
  const navigate = useNavigate();

  // useEffect() - To listen for a successful login, which then leads to redirection to investor page.
  //----------
  useEffect(() => {
    if (!isPending && !error && user) {
      navigate("/investor");
    }
  }, [isPending, error, user, navigate]);

  // Form submission
  //----------------
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Email:", email, "Password:", password);
    login(email, password); // pass info to firebase auth via the useLogin hook
  };

  // handleFocus() - Reset the input css after the user clicks back into input to correct entry after an error
  // -------------
  const handleFocus = () => {
    if (error) {
      setError(null);
    }
  };

  return (
    <div className="login-page">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onFocus={handleFocus}
            className={error ? "input-error" : ""}
            disabled={isPending}
            required
          />
        </div>
        <div className="input-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={error ? "input-error" : ""}
            disabled={isPending}
            required
          />
        </div>
        <button type="submit" disabled={isPending}>
          Login
        </button>
      </form>
      <Link to="/requestlogin" className="request-login-link">
        Request an Investor Login
      </Link>
      <Link to="/passwdreset" className="request-passwd-reset">
        Request Password Reset
      </Link>
      {isPending && <Spinner />}
      {error && <p className="error-msg">{LoginErrorMsg}</p>}
    </div>
  );
}

export default LoginPage;
