import React, { useState, useEffect } from "react";
import useAuthContext from "../hooks/useAuthContext";
import "./CookiePopUp.css";

const CookiePopUp = () => {
  // Context
  // -------
  const { setCookiePolicy } = useAuthContext();

  // State
  // -----
  const [isVisible, setIsVisible] = useState(true);

  // useEffect() - check on launch if accepted cookiePolicy is already in localStorage
  // ----------------------------------------------------------------------------------
  useEffect(() => {
    const isCookieAccepted = localStorage.getItem("cookieAccepted");
    if (isCookieAccepted) {
      setCookiePolicy(true);
      setIsVisible(false);
    } else {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 3000); // 3000 milliseconds = 3 seconds

      return () => clearTimeout(timer); // Clear the timer if the component unmounts
    }
  }, []);

  // handleCookieAccept() - set
  // --------------------
  const handleCookieAccept = () => {
    setCookiePolicy(true);
    localStorage.setItem("cookieAccepted", true); // computer will remember the cookie policy
    setIsVisible(false);
  };

  // handleCookieReject()
  // --------------------
  const handleCookieReject = () => {
    setCookiePolicy(false);
    setIsVisible(false);
  };

  // Disappear
  if (!isVisible) return null;

  return (
    <div className={`cookie-popup ${isVisible ? "visible" : ""}`}>
      <div className="cookie-content">
        <h2>Cookie Policy</h2>
        <p>
          Our website uses cookies to ensure the best browsing experience. By
          accepting, you agree to our use of session cookies which are essential
          for the basic functioning of the site and are automatically deleted
          after your session ends. We also use other types of cookies to enhance
          functionality and analyze site usage.
        </p>
        <div className="cookie-actions">
          <button onClick={handleCookieAccept}>Accept</button>
          <button onClick={handleCookieReject}>Reject</button>
        </div>
      </div>
    </div>
  );
};

export default CookiePopUp;
