import React from "react";
import { disclaimer } from "../data/Disclaimer";
import DisclaimerButtons from "../components/DisclaimerButtons";
import "./DisclaimerPage.css";

function DisclaimerPage() {
  return (
    <div className="disclaimer-page">
      <div className="disclaimer-container">
        {disclaimer}
        <div className="disclaimer-button-section">
          <DisclaimerButtons />
        </div>
      </div>
    </div>
  );
}

export default DisclaimerPage;
