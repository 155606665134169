import { useEffect, useState, useRef } from "react";
import { firebaseDB, firebaseStorage } from "../firebase/config";
import { query, collection, where, getDocs } from "firebase/firestore"; // Firebase DB
import { ref, getDownloadURL } from "firebase/storage"; // Firebase Storage

const useStorageInvLetters = (items, year) => {
  // State
  // -----
  const [urlsList, setUrlsList] = useState([]);
  const prevUrlsListRef = useRef();

  // useEffect()
  // -----------
  useEffect(() => {
    const fetchFilenamesAndDownloadUrls = async () => {
      try {
        // Map over each item to create promises for fetching URLs
        const allUrlsPromises = items.map(async (item) => {
          const { filePath, collectionName, storageName } = item;
          // Query FirebaseDB for Actual FileNames
          const q = query(
            collection(firebaseDB, collectionName),
            where("year", "==", year)
          );
          // console.log("HOOK YEAR", year);
          const querySnapshot = await getDocs(q);
          const queryDocs = querySnapshot.docs.map((doc) => doc.data().letters);
          // console.log("I am query Docs", queryDocs);
          const fileNames = queryDocs.map((obj) => Object.values(obj)); // Ensure results are returned as an array
          // console.log("I am fileNames", fileNames);
          // Create an array of promises to fetch download URLs associated with Filenames
          const urlPromises = fileNames[0].map((f) => {
            const storageRef = ref(
              firebaseStorage,
              `${storageName}/${filePath}${f}`
            );
            // console.log("storageName::", storageName);
            // console.log("filePath::", filePath);
            // console.log("fileName::", f);
            // console.log("PATH::", `${storageName}/${filePath}${f}`);
            // console.log("storageRef", storageRef);
            return getDownloadURL(storageRef);
          });

          // Return resolved URLs for the item
          return await Promise.all(urlPromises); // Return all url links within a collection
        });
        const allUrls = await Promise.all(allUrlsPromises); // Return all url links across all collections
        prevUrlsListRef.current = allUrls; // Cache the fetched URLs in ref
        setUrlsList(allUrls); // Set the state with the fetched URLs
      } catch (error) {
        console.error("Error fetching filenames or download URLs:", error);
      }
    };

    fetchFilenamesAndDownloadUrls();
  }, [items]);
  return { urlsList };
};

export default useStorageInvLetters;
