import React from "react";
import photo1 from "../assets/person1.webp";
import photo2 from "../assets/person3.webp";

const PartnerBios = [
  {
    name: "Jai Rajpal",
    position: "Founder & CEO/CIO",
    photo: photo1,
    bio: (
      <p>
        Jai has nearly 30 years experience in Foreign Exchange and Fixed Income
        trading. Before founding Crescent in 2015, Jai was the Global Head of FX
        at Nomura, London (2012–2014) and prior to that Head of the Fixed Income
        Asia division, based out of Hong Kong (2009–2012). Previously, Jai was
        Head of Fixed Income for S.E. Asia at Lehman Brothers in Singapore
        (2007–2008), and before joining Lehman held various roles at Deutche
        bank, including Global Head of EMFX trading at Deutsche Bank, Singapore
        (1994–2007). Jai holds a degree in Electrical Engineering from the
        Indian Institute of Technology, Delhi and a postgraduate diploma in
        Management from the Indian Institute of Management, Calcutta.
      </p>
    ),
  },

  {
    name: "Lawrence Parsons",
    position: "COO & Director",
    photo: photo2,
    bio: (
      <p>
        Lawrence is our COO, joining Crescent in 2017. Previously, he was the
        COO/CFO and one of the founding principals at Saka Capital, a Long/Short
        Asia Credit Hedge Fund (2009–2017). Before Saka, Lawrence was the
        Operations Manager at R3 Capital – a hedge fund that spun out of Lehman
        Brothers (2008–2009), and prior to the spin-off Lawrence spent 16 years
        at Lehman Brothers in various operational roles in London and Singapore,
        including being the Middle Office Operations Manager for the Global
        Principal Strategies Trading Division at Lehman Brothers.
      </p>
    ),
  },

  {
    name: "Atin Gupta",
    position: "Senior Portfolio Manager, Partner",
    photo: photo1,
    bio: (
      <p>
        Atin Gupta is a senior Portfolio Manager and partner at Crescent,
        joining the firm in 2018. Atin's career in the financial industry began
        in 2005 as a Manager in Rates trading at Citibank, India. In 2010, Atin
        moved to Goldman Sachs, India, where he took on the role of Head of
        Primary Dealer, and was responsible for the primary dealer activities in
        India rates. In 2012, Atin returned to Citibank, India, where he was
        appointed as Head of Rates trading. Two years later, he was appointed as
        Director, Macro Trading, Citibank, Singapore. In this role, he was
        responsible for managing the India and Indonesia local teams and Hub
        rates team. Atin holds a postgraduate diploma in Business Management
        from Indian Institute of Management, Kozhikode.
      </p>
    ),
  },

  {
    name: "Arun Ganesh",
    position: "Senior Portfolio Manager, Partner",
    photo: photo1,
    bio: (
      <p>
        Arun Ganesh is a Senior Portfolio Manager and partner at Crescent,
        joining the firm in 2020. Most recently, Arun served as a Portfolio
        Manager at Graticule Asset Management Singapore (2016-19). Prior to
        that, he was a Director of Fixed Income and FX Trading at Citibank
        Singapore, where he was responsible for managing the Asia EM FX Options
        portfolio and the Latam EM FX Options portfolio in (2008-2016). Before
        joining Citibank, Arun began his career on the G10 and EM FX Options
        desk at Lehman Brothers, working in both Tokyo and Singapore from 2006
        to 2008. Arun holds a Bachelor's Degree in Biotechnology from the Indian
        Institute of Technology Madras.
      </p>
    ),
  },
  {
    name: "Stewart Newnham",
    position: "Head of Research, Partner",
    photo: photo2,
    bio: (
      <p>
        Stewart joined Crescent as Head of Ressearch in 2015. Previously, he was
        the Chief Strategist at HighIsland Capital Partners (2012 to 2014), and
        the Head of Asia ex-Japan FX Strategy at Morgan Stanley in Hong Kong
        (2006 to 2012). Prior to moving to Asia, Stewart worked in London in
        various roles as a Currency Strategist at Morley Fund Management
        (2003-2006), State Street Bank (1999-2002) and Commerzbank (1998-1999)
        and began his career as a Treasury Economist at First Chicago NBD.
        Stewart holds a BA in Economics with Econometrics from the University of
        Kent, and a MSc Economics, MSc Financial Engineering and MSc Computer
        Science, all from Birkbeck College, University of London. Stewart is
        also a Chartered Financial Analyst (CFA) charter holder.
      </p>
    ),
  },
];

export default PartnerBios;
