import React, { useState } from "react";
import useAuthContext from "../hooks/useAuthContext";
import SpinnerHomePage from "../components/SpinnerHomePage";
import Footer from "../components/Footer";
import CookiePopUp from "../components/CookiePopUp";
import "./Page.css";

function Page() {
  // Context
  // -------
  const { isAcceptCookiePolicy } = useAuthContext();
  // isAcceptCookiePolicy: false,

  // State
  // -----
  const [showFooter, setFooter] = useState(true);

  // setCloseFooter() - prop function to pass to Footer
  // ----------------
  const setCloseFooter = () => {
    setFooter(!showFooter);
  };

  return (
    <>
      <div className="page">
        <SpinnerHomePage />
        <div className="splashcontainer">
          <h1>Crescent</h1>
          <h2>Asset Management Asia</h2>
        </div>
      </div>
      {!isAcceptCookiePolicy && <CookiePopUp />}
      {showFooter && <Footer setCloseFooter={setCloseFooter} />}
    </>
  );
}

export default Page;
