import React, { useReducer, useEffect } from "react";
import { firebaseAuth } from "../firebase/config";
import AuthContext from "./AuthContext";
import AuthReducer from "./AuthReducer";
import {
  LOGIN,
  LOGOUT,
  IS_AUTH_READY,
  IS_LOADING,
  SET_COOKIE_POLICY,
} from "./Types";

const AuthProvider = (props) => {
  // Init Values for State Going into Reducer
  const initValues = {
    isAcceptCookiePolicy: false,
    user: null,
    isAuthReady: false,
    isLoading: true,
  };

  // Reducer
  const [state, dispatch] = useReducer(AuthReducer, initValues);

  // Functions Exposed in Context
  // -----------------------------
  const setCookiePolicy = (isAcceptCookiePolicy) => {
    dispatch({
      type: SET_COOKIE_POLICY,
      payload: isAcceptCookiePolicy,
    });
  };

  const loginUser = (user) => {
    dispatch({
      type: LOGIN,
      payload: user,
    });
  };

  const logoutUser = () => {
    dispatch({
      type: LOGOUT,
    });
  };

  // One-off Check with Firebase if user state has changed by user surfs to another website.
  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      dispatch({
        type: IS_AUTH_READY,
        payload: user,
      });
      dispatch({
        type: IS_LOADING,
        payload: false,
      });
      return () => unsubscribe();
    });
  }, []);

  console.log("Context State", state);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        loginUser,
        logoutUser,
        setCookiePolicy,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
