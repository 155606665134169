import { useState, useEffect } from "react";
import { firebaseAuth } from "../firebase/config";
import { signOut } from "firebase/auth";
import useAuthContext from "./useAuthContext";
import useTokens from "./useTokens";

const useLogout = () => {
  // Make a call to Context for the logout()
  const { logoutUser } = useAuthContext();

  // State Variables
  // ----------------
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false); // Check if user has cancelled by clicking other buttons
  const [intervalCheckId, setIntervalCheckId] = useState(null); // Check if user has cancelled by clicking other buttons

  // Hooks
  // -----
  const { endCookieSession } = useTokens();

  // useEffect()
  // -----------
  // Defence listener to detect if the user has clicked elsewhere and unmounted
  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);
  // useEffect to clear the interval when the component using this hook unmounts
  useEffect(() => {
    return () => {
      if (intervalCheckId) clearInterval(intervalCheckId);
    };
  }, [intervalCheckId]);

  // logout()
  // --------
  const logout = async () => {
    setError(false);
    setIsPending(true);

    try {
      await endCookieSession();
      console.log("clearing out cookies");
    } catch (err) {
      console.log("Clearing Cookie sessions failed: ", err.message);
      setIsPending(false);
      setError(err.message);
      return; // If we fail to clear the session, we stop the logout process.
    }
    try {
      await signOut(firebaseAuth);
      console.log("signing out");
      logoutUser();
      // update state
      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      if (!isCancelled) {
        console.log("logout failed!!!", err);
        setError(err.message);
        setIsPending(false);
      }
    }
  };

  // checkIfLoginExpired() - check localstorage expiryDate to see if the login has expired
  // ---------------------
  const checkIfLoginExpired = () => {
    const timer = 0.5 * 60 * 60 * 1000; // 1/2 hr timer check
    // inner function to check if the login has expired
    const timeCheck = () => {
      const timeNow = new Date().getTime();
      const extractedLoginExpiryTime = localStorage.getItem("loginExpiryTime");
      if (!extractedLoginExpiryTime) {
        console.log("No loginExpiryTime found in localstorage");
        logout();
      }
      const loginExpiryTime = parseInt(extractedLoginExpiryTime);
      // console.log("testing loginExpiryTime....");
      // console.log("loginExpiryTime: ", loginExpiryTime);
      // console.log("timeNow: ", timeNow);
      if (timeNow > loginExpiryTime) {
        logout();
      }
    };
    // Clear any existing interval
    if (intervalCheckId) clearInterval(intervalCheckId);

    // Set the new interval
    const newIntervalId = setInterval(timeCheck, timer);
    setIntervalCheckId(newIntervalId);
  };

  return {
    logout,
    error,
    isPending,
    checkIfLoginExpired,
  };
};

export default useLogout;
