import React from "react";
import "./PartnerCard.css";

function PartnerCard({ name, position, photo, bio }) {
  return (
    <div className="partner-card">
      <img src={photo} alt={name} className="partner-photo" />
      <div className="partner-details">
        <h2>{name}</h2>
        <h3>{position}</h3>
        {bio}
      </div>
    </div>
  );
}

export default PartnerCard;
