import { useContext } from "react";
import AuthContext from "../contexts/AuthContext";

// The useContext is wrapped in hook in order to 1) include a defence check; 2) abstract

const useAuthContext = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw Error("AuthContext Not in Scope");
  }

  return authContext;
};

export default useAuthContext;
